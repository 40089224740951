import { i18n, TFunction } from "i18next";
import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { RouteComponentProps } from 'react-router';
import { pushAlert } from "../../actions/alert-actions";
import { getDoctorList } from "../../actions/doctor-action";
import { DOCTOR_FILTER, _doctorFilter } from "../../actions/filter-actions";
import { deletePatientDoctor, savePatientDoctor } from "../../actions/patient-doctor-action";
import { cancel } from "../../actions/scheduling-item-record-actions";
import { DoctorSort, IDoctorFilter, VisitType } from "../../models/doctor-filter";
import { PagingOptions } from '../../models/paging-options';
import { IPatientDoctor } from "../../models/patient-doctor";
import { ISchedulingItemFilter } from "../../models/scheduling-item-filter";
import { ISchedulingItemRecord } from "../../models/scheduling-item-record";
import { ISchedulingItemRecordFilter } from "../../models/scheduling-item-record-filter";
import { IAuthState } from "../../states/auth-state";
import { IDoctorState } from "../../states/doctor-state";
import { IFiltersState } from "../../states/flters-state";
import { ISchedulingItemRecordsState } from "../../states/scheduling-item-records-state";
import { ISchedulingItemsState } from "../../states/scheduling-items-state";
import { RootState } from "../../store";
import FiltersComponentContainer from './../Filters/Filters';
import QuickAccessComponentContainer from './../QuickAccess/QuickAccess';
import DoctorItem, { DoctorsType } from './DoctorItem';
import './Doctors.scss';
import './../Clinics/Filter.scss';
import Pagination from './Pagination';
import { IDictionaryState } from "../../states/dictionary-state";
import { IDictionary } from "../../models/dictionary";
import moment from "moment";
import history from "./../../history";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ILoadingState, LoadingSubject } from "../../states/loading-state";
import { LoadingCenter } from "../Loading/Loading";
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';
import close2 from "./../../images/close2.svg"
interface MyProps {
    doctor: IDoctorState;
    auth: IAuthState;
    filters: IFiltersState;
    dictionary: IDictionaryState;
    loading: ILoadingState;
    location: Location;
    t: TFunction;
    i18n: i18n;

    getDoctorList: (
        filter: IDoctorFilter | undefined,
        page: number,
    ) => void;
    deletePatientDoctor: (
        id: number,
    ) => void;
    savePatientDoctor: (
        patientDoctor: IPatientDoctor,
    ) => void;
    updatePatientDoctor: (
        patientDoctor: IPatientDoctor,
    ) => void;
    _doctorFilter: (doctorFilter: IDoctorFilter | undefined, my: boolean) => {
        type: typeof DOCTOR_FILTER,
        doctorFilter: IDoctorFilter | undefined,
        my: boolean,
    };

    schedulingItems: ISchedulingItemsState;
    schedulingItemRecords: ISchedulingItemRecordsState;
    cancel: (
        item: ISchedulingItemRecord,
        filter: ISchedulingItemFilter | ISchedulingItemRecordFilter) => void;
    pushAlert: (text: string) => void;
}

interface MyState {
    dayOfWeekValue: string;
    pagingOptions: PagingOptions;
    showFilters: boolean;
    filter: IDoctorFilter;
}

interface Params {
    name?: string | undefined;
}

type FilterTypes = 'NAME' | 'LANGUAGE' | 'PROCEDURE' | 'SPECIALIZATION' | 'SORTING' | 'DISEASE' | 'CLINIC' | 'START' | 'END' | 'ONLINE' | 'OFFLINE';

class DoctorsComponent extends Component<MyProps & RouteComponentProps<Params>, MyState> {

    constructor(props: MyProps & RouteComponentProps<Params>) {
        super(props);
        this.state = {
            dayOfWeekValue: '1',
            pagingOptions: {
                page: 0,
                pageSize: 8,
                totalPages: 0,
            },
            showFilters: false,
            filter: this.getFilter(),
        };
    }

    componentDidMount() {
        const { pagingOptions } = this.state;
        this.sendRequest(pagingOptions.page, this.getFilter());
    }

    getFilter = () => {
        const params = new URLSearchParams(this.props.location.search);
        return {
            name: params.get('name') || undefined,
            procedure: params.get('procedure') || undefined,
            specialization: params.get('specialization') || undefined,
            disease: params.get('disease') || undefined,
            sex: params.get('sex') || undefined,
            clinic: params.get('clinic') || undefined,
            language: params.get('language') || undefined,
            online: params.get('online') || undefined,
            offline: params.get('offline') || undefined,
            start: params.get('start') || undefined,
            end: params.get('end') || undefined,
            sort: params.get('sort') || undefined,
        } as IDoctorFilter;
    }

    applyFilter(filter: IDoctorFilter) {
        const params = Object.keys(filter).filter(p => filter[p]).map(p => `${p}=${filter[p]}`).join('&');
        history.push(this.props.location.pathname + (params ? '?' + params : ''))
    }

    sendRequest = async (page: number, filter: IDoctorFilter) => {
        const doctorcType = this.props.location.pathname;
        if (doctorcType === '/doctors') {
            filter = { ...filter, onDuty: false, myClinics: false, myDoctors: false };
        } else if (doctorcType === '/my-doctors') {
            filter = { ...filter, myDoctors: true };
        } else if (doctorcType === '/duty-doctors') {
            filter = { ...filter, onDuty: true };
        } else if (doctorcType === '/my-clinic-doctors') {
            filter = { ...filter, myClinics: true };
        }

        await this.props.getDoctorList(filter, page);
        if (!filter.myDoctors) {
            await this.props.getDoctorList({ ...filter, myDoctors: false }, page);
        }
    }

    filterValues() {
        const { filter } = this.state;
        const langMap = this.props.dictionary.languagesMap;
        const procMap = this.props.dictionary.proceduresMap;
        const specMap = this.props.dictionary.specializationTypesMap;
        const diseaseMap = this.props.dictionary.diseasesMap;
        const clinicsMap = this.props.dictionary.clinicsMap;

        const lang = langMap && filter.language ? langMap[filter.language] : null;
        const proc = procMap && filter.procedure ? procMap[filter.procedure] : null;
        const spec = specMap && filter.specialization ? specMap[filter.specialization] : null;
        const disease = diseaseMap && filter.disease ? diseaseMap[filter.disease] : null;
        const clinic = clinicsMap && filter.clinic ? clinicsMap[filter.clinic] : null;

        return [{ filter: "LANGUAGE", value: lang }, { filter: "PROCEDURE", value: proc }, { filter: "SPECIALIZATION", value: spec }, { filter: "DISEASE", value: disease }, { filter: "CLINIC", value: clinic }].filter(c => !!c.value) as { filter: FilterTypes, value: IDictionary }[];
    }

    search() {
        this.setState({ showFilters: false });
        this.applyFilter(this.state.filter);
        this.sendRequest(this.state.pagingOptions.page, this.state.filter);
    }

    changePageOptions = (pagingOptions: PagingOptions) => {
        this.setState({ pagingOptions: { ...pagingOptions } });
        this.sendRequest(pagingOptions.page, this.getFilter());
    }

    setFilter = (value: any, type: FilterTypes) => {
        let { filter } = this.state;
        let current: IDoctorFilter;
        switch (type) {
            case "NAME":
                filter = { ...filter, name: !!value ? value : undefined };
                break;
            case "LANGUAGE":
                filter = { ...filter, language: value > 0 ? value : undefined };
                break;
            case "PROCEDURE":
                filter = { ...filter, procedure: value > 0 ? value : undefined };
                break;
            case "SPECIALIZATION":
                filter = { ...filter, specialization: value > 0 ? value : undefined };
                break;
            case "DISEASE":
                filter = { ...filter, disease: value > 0 ? value : undefined };
                break;
            case "CLINIC":
                filter = { ...filter, clinic: value > 0 ? value : undefined };
                break;
            case "END":
                filter = { ...filter, end: value ? value : undefined };
                current = { ...this.getFilter(), end: filter.end };
                this.applyFilter(current);
                this.sendRequest(this.state.pagingOptions.page, current);
                break;
            case "START":
                filter = { ...filter, start: value ? value : undefined };
                current = { ...this.getFilter(), start: filter.start };
                this.applyFilter(current);
                this.sendRequest(this.state.pagingOptions.page, current);
                break;
            case "ONLINE":
                filter = { ...filter, online: value ? value : undefined };
                current = { ...this.getFilter(), online: filter.online };
                if (filter.online || filter.offline) {
                    filter.start = filter.start || moment().format('yyyy-MM-DD');
                    filter.end = filter.end || moment().add(7, 'days').format('yyyy-MM-DD');
                    current = { ...current, end: filter.end, start: filter.start };
                } else {
                    current = { ...current, end: undefined, start: undefined };
                }
                this.applyFilter(current);
                this.sendRequest(this.state.pagingOptions.page, current);
                break;
            case "OFFLINE":
                filter = { ...filter, offline: value ? value : undefined };
                current = { ...this.getFilter(), offline: filter.offline };
                if (filter.online || filter.offline) {
                    filter.start = filter.start || moment().format('yyyy-MM-DD');
                    filter.end = filter.end || moment().add(7, 'days').format('yyyy-MM-DD');
                    current = { ...current, end: filter.end, start: filter.start };
                } else {
                    current = { ...current, end: undefined, start: undefined };
                }
                this.applyFilter(current);
                this.sendRequest(this.state.pagingOptions.page, current);
                break;
            case "SORTING":
                filter = { ...filter, sort: value };
                current = { ...this.getFilter(), sort: value };
                this.applyFilter(current);
                this.sendRequest(this.state.pagingOptions.page, current);
                break;
            default:
                break;
        }
        this.setState({ filter: filter });
    };

    getDoctorType(): DoctorsType | undefined {
        const doctorcType = this.props.location.pathname;
        if (doctorcType === '/doctors') {
            return undefined;
        } else if (doctorcType === '/my-doctors') {
            return 'MyDoctors';
        } else if (doctorcType === '/duty-doctors') {
            return 'DutyDoctors';
        } else if (doctorcType === '/my-clinic-doctors') {
            return 'MyClininDoctors';
        }
    }

    render() {
        const { t } = this.props;
        const { showFilters } = this.state;

        const doctorsList = this.props?.location?.pathname === '/my-doctors' ? this.props.doctor.mydoctors : this.props.doctor.doctors;

        const visitType = [
            { name: 'In the clinic', value: 0 },
            { name: 'Online', value: 1 }
        ];

        const sortDoctors = [
            { name: 'By raiting', value: 0 },
            { name: 'By work experience', value: 1 },
            { name: 'Alphabetically', value: 2 },
        ];

        const lang = this.props.dictionary.languages || [];
        const procedure = this.props.dictionary.procedures || [];
        const specs = this.props.dictionary.specializationTypes || [];
        const disease = this.props.dictionary.diseases || [];
        const stationar = this.props.dictionary.clinics || [];

        const filterValues = this.filterValues();
        const filter = this.state.filter;

        console.log('__doctors', this.props)
        console.log('filter123', filter)

        return (
            <div>
                <div className='filters extra-top-filter'>
                    <div className='filters-wrapper'>
                        <h1 className='big-title'>{t('Appointment with the best doctors!')}</h1>
                        <div className='list-filters'>
                            <div className='list-filters__default-filters'>
                                <Form.Label className="regular-text">{t('Doctor name')}</Form.Label>
                                <Form.Control size="sm" type="text" onKeyDown={(e) => { if (e.key === 'Enter') return this.search() }} value={filter.name || undefined} onChange={(e) => this.setFilter(e.target.value, 'NAME')} />
                                <Button className='blue-btn list-filters__search' variant="primary" onClick={() => this.search()}>{t('Search')}</Button>
                            </div>
                            <span className='extra-filters__label'>
                                <span className='extra-filters__accordeon'
                                    onClick={() => this.setState({ showFilters: !this.state.showFilters })}>
                                    {t('Additional filters')} {showFilters ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </span>
                                {filterValues.length > 0 && <span>:</span>}
                                {filterValues.map((f) => {
                                    return <span className='extra-filters__result'>
                                        {f.value.name}<img onClick={() => this.setFilter(undefined, f.filter)} src={close2} />
                                    </span>
                                })}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='extra-filters__wrapper'>
                    <div className='extra-filters__filters'>
                        {showFilters && <div className='extra-filters__row'>
                            <div className='extra-filters__column'>
                                <Form.Label
                                    className="regular-text"
                                >{t('Hospital')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.clinic || undefined}
                                    onChange={(e) => this.setFilter(e.target.value, 'CLINIC')}
                                >
                                    <option value={0}>{t('Hospital')}</option>
                                    {stationar.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label className="regular-text">{t('Procedure')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.procedure || undefined}
                                    onChange={(e) => this.setFilter(e.target.value, 'PROCEDURE')}
                                >
                                    <option value={0}>{t('Procedure')}</option>
                                    {procedure.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label
                                    className="regular-text"
                                >{t('Specialization')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.specialization || undefined}
                                    onChange={(e) => this.setFilter(e.target.value, 'SPECIALIZATION')}
                                >
                                    <option value={0}>{t('Specialization')}</option>
                                    {specs.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label
                                    className="regular-text"
                                >{t('Disease')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.disease || undefined}
                                    onChange={(e) => this.setFilter(e.target.value, 'DISEASE')}
                                >
                                    <option value={0}>{t('Disease')}</option>
                                    {disease.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label className="regular-text">{t('Language')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.language || undefined}
                                    onChange={(e) => this.setFilter(e.target.value, 'LANGUAGE')}
                                >
                                    <option value={0}>{t('Language')}</option>
                                    {lang.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                        </div>}
                    </div>
                </div>

                <div style={{ maxWidth: '910px', margin: '0 auto 20px auto' }}><Breadcrumbs location={this.props.location} /></div>

                <div className='doctors-main-wrapper'>
                    <div className='doctors-filters'>
                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', marginRight: '10px'}}>
                            <p>{t('selectConsultationType')}</p>
                            <ButtonGroup className="doctors-filters__type" toggle>
                                {visitType.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        type="checkbox"
                                        variant="light"
                                        name={radio.name}
                                        value={radio.value}
                                        checked={radio.value === VisitType.Clinic ? filter.offline : filter.online}
                                        onChange={(e) => {
                                            this.setFilter(e.currentTarget.checked, radio.value === VisitType.Clinic ? 'OFFLINE' : 'ONLINE')
                                        }}
                                    >
                                        {t(radio.name)}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </div>
                        {(filter.offline || filter.online) ? <div className="doctors-filters__date-range">
                            <input className="date-input" type="date" value={this.state.filter.start} onChange={(e) => this.setFilter(e.target.value, 'START')} />
                            <input className="date-input" type="date" value={this.state.filter.end} onChange={(e) => this.setFilter(e.target.value, 'END')} />
                        </div> : <p style={{fontSize: '12px', margin: '12px'}}>{t('timeAvailableAfterTypeSelect')}</p>}
                        <Form.Control className="doctors-filters__sorting"
                            as="select"
                            value={filter.sort}
                            onChange={(e) => this.setFilter(e.target.value, 'SORTING')}
                        >
                            {sortDoctors.map((sd) => {
                                return <option value={sd.value}>{t(sd.name)}</option>
                            })}
                        </Form.Control>
                    </div>
                    {!doctorsList || !doctorsList.length && <div className='big-title no-result'>{this.props.loading.subjects.has(LoadingSubject.DoctorList) ? <LoadingCenter backgroundColor='#eef1f8' /> : t('No results were found')}</div>}

                    {doctorsList && doctorsList.map((doctor) => {
                        return (
                            <DoctorItem
                                refreshDoctors={() => this.sendRequest(this.state.pagingOptions.page, this.state.filter)}
                                key={doctor.id}
                                doctor={doctor}
                                doctorsType={this.getDoctorType()}
                            />
                        )
                    })}
                    <Pagination
                        changePageOptions={this.changePageOptions}
                        start={0}
                        pagingOptions={
                            { ...this.state.pagingOptions, totalPages: this.props.doctor.fullTotalCount }} />
                </div>
                <QuickAccessComponentContainer />
                <FiltersComponentContainer />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    doctor: state.doctor,
    auth: state.auth,
    filters: state.filters,
    dictionary: state.dictionary,

    schedulingItems: state.schedulingItems,
    schedulingItemRecords: state.schedulingItemRecords,

    loading: state.loading,
});

const mapDispatchToProps = {
    getDoctorList,
    _doctorFilter,
    deletePatientDoctor,
    savePatientDoctor,
    cancel,
    pushAlert,
};

let DoctorsComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    DoctorsComponent
);

export default withTranslation()(DoctorsComponentContainer);