import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import "./Breadcrumbs.scss";
import { withTranslation } from "react-i18next";
import { i18n, TFunction } from "i18next";
import { Link } from "react-router-dom";
import { IAuthState } from "../../states/auth-state";
import { UserRole } from "../../models/user-role.enum";

interface MyProps {
  t: TFunction;
  i18n: i18n;
  location: Location;
  auth: IAuthState;
}

interface MyState {}

class Breadcrumbs extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  generateLinks() {
    const { t } = this.props;

    const { pathname } = this.props.location;
    const { userToken } = this.props.auth;

    if (pathname === "/profile") {
      return <p>{t("Information about me")}</p>;
    }
    if (pathname === "/my-clinics") {
      return <p>{t("My clinics")}</p>;
    }
    if (pathname === "/clinics") {
      return <p>{t("Clinics")}</p>;
    }
    if (pathname === "/my-doctors") {
      return <p>{t("My doctors")}</p>;
    }
    if (pathname === "/doctors") {
      // userToken.role === 1 this is clinic
      // userToken.role === 2 this is doctor
      // userToken.role === 3 this is pacient
      // userToken.role === undefined this did't authorized
      const text =
        userToken && userToken.role === 2 ? "Doctor-to-Doctor" : "Doctors";
      return <p>{t(text)}</p>;
    }

    const isNotPatient = userToken?.role !== UserRole.Patient;

    if (pathname === "/medical-card") {
      return (
        <>
          <Link to={"/doctors-user-access"}> {t("Access for doctors")}</Link>
          <span>{">"}</span>
          <p>{t("Medical card")}</p>
        </>
      );
    }
    if (pathname === "/online-appointment") {
      const text =
        userToken && userToken.role === 3
          ? "My consultations"
          : "My online receptions";
      return <p>{t(text)}</p>;
    }
    if (pathname === "/duty-doctors") {
      return <p>{t("Duty doctors")}</p>;
    }
    if (pathname === "/doctor-duty") {
      return <p>{t("My duties")}</p>;
    }
    if (pathname === "/doctor/schedule-details") {
      return <p>{t("My scheduling")}</p>;
    }

    if (pathname === "/doctors-user-access") {
      return <p>{t("Access for doctors")}</p>;
    }
    if (pathname === "/my-indicators") {
      return (
        <>
          <Link to={"/medical-card"}> {t("My Medical Chart")}</Link>
          <span>{">"}</span>
          <p>{`${isNotPatient ? '' : 'My '}${t("Indicators")}`}</p>
        </>
      );
    }
  }

  render() {
    const { t } = this.props;
    const { userToken } = this.props.auth;

    let mainLink = "/";

    if (userToken) {
      switch (userToken.role) {
        case UserRole.Doctor:
          mainLink = "/personal-cabinet-doctor";
          break;
        case UserRole.Clinic:
          mainLink = "/personal-cabinet-clinic";
          break;
        default:
          mainLink = "/personal-cabinet-patient";
          break;
      }
    }

    return (
      <div className="breadcrumbs-wrapper">
        <Link to={mainLink}>
          {" "}
          {t(mainLink === "/" ? "Home page" : "Profile")}
        </Link>
        <span>{">"}</span>
        {this.generateLinks()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

let BreadcrumbsComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Breadcrumbs);

export default withTranslation()(BreadcrumbsComponentContainer);
